import { createStore } from "vuex";
import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import {
    collection,
    onSnapshot,
    addDoc,
    setDoc,
    updateDoc,
    runTransaction,
    doc,
    increment,
    where,
    query,
    orderBy,
    arrayUnion,
} from "firebase/firestore";
//import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { db, collections, subCollections } from "../firebaseConfig";

//const logrocketPlugin = createPlugin(LogRocket);

const paymentMethod = {
    ZELLE: "zelle",
    CREDIT_CARD: "credit_card",
};

const stripeFees = {
    MDR: 0.961,
    TRXN_FEE: 0.5,
};

const CLIENT_IP_SERVICE = "https://api.bigdatacloud.net/data/client-info";

Object.freeze(paymentMethod);
Object.freeze(stripeFees);

//const messaging = getMessaging(firebase);
const auth = getAuth();
onAuthStateChanged(auth, (user) => {
    if (user) {
        store.commit("setLoggedInUser", user);
        store.commit("setIsLoggedIn", true);
        store.dispatch("fetchProfile", user);
        //if(isSupported(messaging)) store.dispatch("saveMessagingDeviceToken", user);
        store.dispatch("getMyOrders", user);

        const domain = window.location.hostname;
        if (domain != "localhost") {
            LogRocket.identify(`${user.uid}`, {
                name: `${user.displayName}`,
                email: `${user.email}`,

                // Add your own custom user variables here, ie:
                emailVerified: `${user.emailVerified}`,
            });
        }
    }
    store.dispatch("init");
});

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
console.log(params);

export const store = createStore({
    //plugins: [logrocketPlugin],
    state: {
        pageTitle: "",
        messages: null,
        currentChatId: "",
        loggedInUser: {},
        userProfile: {},
        availability: {},
        selectedHotel: {},
        selectedTransport: {},
        selectedAddons: [],
        selectedEvent: {},
        selectedCostumes: [],
        selectedCostumesData: [],
        selectedPaymentMethod: null,
        basePrice: 0,
        costumePrice: 0,
        totalTravellers: 0,
        totalTripCost: 0,
        totalTripDeposit: 0,
        timeToTrip: 0,
        totalTripDiscount: 0,
        travellers: {},
        paymentAmount: 0,
        stripePaymentAmount: 0,
        hotelOptions: [],
        transportOptions: [],
        costumeOptions: [],
        addonsOptions: [],
        pricePerTraveller: [],
        payFullAmount: null,
        isReservationFormBusy: false,
        stripeClientSecret: "",
        myOrders: null,
        pageLoading: true,
        initialized: false,
        imgPlaceholder: "https://dummyimage.com/500x500/ddd/f1f1f1&text=add-image",
        storageBucket: "https://firebasestorage.googleapis.com/v0/b/app-soca-islands.appspot.com/o",
        userProfile: {},
        privateData: {},
        profileDoc: "",
        marketplace: null,
        stagingMarketplace: [],
        stagingCrewfinder: [],
        crewfinder: null,
        isLoggedIn: false,
        currentChat: [],
        fcmToken: "",
        eventsList: [],
    },
    mutations: {
        setPageTitle(state, val) {
            state.pageTitle = val;
        },
        setStripeClientSecret(state, val) {
            state.stripeClientSecret = val;
        },
        setIsReservationFormBusy(state, val) {
            state.isReservationFormBusy = val;
        },
        setMyOrders(state, val) {
            state.myOrders = val;
        },
        setTotalTravellers(state, val) {
            state.totalTravellers = Number(val);
        },
        setTravellers(state, val) {
            state.travellers = val;
        },
        setPricePerTraveller(state, { index, price }) {
            state.pricePerTraveller[index] = Number(price);
        },
        setPaymentAmount(state, val) {
            state.paymentAmount = Number(val);
        },
        setTotalTripCost(state, val) {
            state.totalTripCost = Number(val);
        },
        setTotalTripDeposit(state, val) {
            state.totalTripDeposit = Number(val);
        },
        setTotalTripDiscount(state, val) {
            state.totalTripDiscount = Number(val);
        },
        setStripePaymentAmount(state, val) {
            state.stripePaymentAmount = Number(val);
        },
        setSelectedTransport(state, val) {
            state.selectedTransport = val;
        },
        setSelectedHotel(state, val) {
            state.selectedHotel = val;
        },
        setSelectedCostumes(state, val) {
            state.selectedCostumes = val;
        },
        setSelectedCostumesData(state, val) {
            state.selectedCostumesData = val;
        },
        setSelectedPaymentMethod(state, val) {
            state.selectedPaymentMethod = val;
        },
        setSelectedEvent(state, val) {
            state.selectedEvent = val;
        },
        setSelectedAddons(state, val) {
            state.selectedAddons = val;
        },
        setTimeToTrip(state, val) {
            state.timeToTrip = val;
        },
        setAvailability(state, val) {
            state.availability = val;
        },
        setAvail(state, val) {
            state.selectedEvent = val;
        },
        setBasePrice(state, val) {
            state.basePrice = val;
        },
        setCostumePrice(state, val) {
            state.costumePrice = val;
        },
        setHotelOptions(state, val) {
            state.hotelOptions = val;
        },
        setAddonsOptions(state, val) {
            state.addonsOptions = val;
        },
        setTransportOptions(state, val) {
            state.transportOptions = val;
        },
        setCostumeOptions(state, val) {
            state.costumeOptions = val;
        },
        setPayFullAmount(state, val) {
            state.payFullAmount = val;
        },
        setUserProfile(state, val) {
            state.userProfile = val;
        },
        setPrivateData(state, val) {
            state.privateData = val;
        },
        setProfileDoc(state, val) {
            state.profileDoc = val;
        },
        setPageTitle(state, val) {
            state.pageTitle = val;
        },
        setMarketplace(state, val) {
            state.marketplace = val;
        },
        updateMarketplaceItem(state, val) {
            Object.assign(state.marketplace[val.index], val.data);
        },
        removeMarketplaceItem(state, val) {
            state.marketplace.splice(val, 1);
        },
        setInitialized(state, val) {
            state.initialized = val;
        },
        setStagingMarketplace(state, val) {
            state.stagingMarketplace.unshift(val);
        },
        clearStagingMarketplace(state) {
            state.stagingMarketplace = [];
        },
        setCrewfinder(state, val) {
            state.crewfinder = val;
        },
        updateCrewfinderItem(state, val) {
            Object.assign(state.crewfinder[val.index], val.data);
        },
        removeCrewfinderItem(state, val) {
            state.crewfinder.splice(val, 1);
        },
        setStagingCrewfinder(state, val) {
            state.stagingCrewfinder.unshift(val);
        },
        clearStagingCrewfinder(state) {
            state.stagingCrewfinder = [];
        },
        setIsLoggedIn(state, val) {
            state.isLoggedIn = val;
        },
        setMessages(state, val) {
            state.messages = val;
        },
        setCurrentChatId(state, val) {
            state.currentChatId = val;
        },
        setCurrentChat(state, val) {
            state.currentChat = val;
        },
        setPageLoading(state, val) {
            state.pageLoading = val;
        },
        setLoggedInUser(state, val) {
            state.loggedInUser = val;
        },
        setFcmToken(state, val) {
            state.fcmToken = val;
        },
        setEventsList(state, val) {
            state.eventsList = val;
        },
    },
    actions: {
        logOut() {
            const auth = getAuth();
            signOut(auth)
                .then(() => {
                    window.location.replace("/");
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async getMyOrders({ commit }, user) {
            const ordersRef = query(
                collection(db, collections.ORDERS),
                where("order_status", "in", ["in-review", "processing", "complete"]),
                where("user", "==", user.uid),
                orderBy("ref", "desc")
            );
            onSnapshot(
                ordersRef,
                (querySnapshot) => {
                    let orders = [];
                    querySnapshot.forEach((doc) => {
                        orders.push({ id: doc.id, ...doc.data() });
                    });
                    commit("setMyOrders", orders);
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        async getEvents({ commit }) {
            const eventsRef = query(collection(db, collections.EVENTS), where("public", "==", true));
            onSnapshot(
                eventsRef,
                (querySnapshot) => {
                    let events = [];
                    querySnapshot.forEach((doc) => {
                        events.push({ id: doc.id, ...doc.data() });
                    });
                    commit("setEventsList", events);
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        async getAvailabilityData({ commit, state }, { availability, eventId }) {
            //set base price
            commit("setBasePrice", availability.start_price);
            commit("setTotalTripDiscount", availability?.discount || 0);
            const hotelRef = collection(
                db,
                `${collections.EVENTS}/${eventId}/${subCollections.AVAILABILITY}/${availability.id}/${subCollections.HOTELS}`
            );
            onSnapshot(
                hotelRef,
                (querySnapshot) => {
                    let hotels = [];
                    querySnapshot.forEach((doc) => {
                        hotels.splice(0, 0, { id: doc.id, ...doc.data() });
                    });
                    commit("setHotelOptions", hotels);
                },
                (error) => {
                    console.log(error);
                }
            );

            const transportRef = collection(
                db,
                `${collections.EVENTS}/${eventId}/${subCollections.AVAILABILITY}/${availability.id}/${subCollections.TRANSPORT}`
            );
            onSnapshot(
                transportRef,
                (querySnapshot) => {
                    let transport = [];
                    querySnapshot.forEach((doc) => {
                        transport.push({ id: doc.id, ...doc.data() });
                    });
                    commit("setTransportOptions", transport);
                },
                (error) => {
                    console.log(error);
                }
            );

            const costumesRef = collection(
                db,
                `${collections.EVENTS}/${eventId}/${subCollections.AVAILABILITY}/${availability.id}/${subCollections.COSTUMES}`
            );
            onSnapshot(
                costumesRef,
                (querySnapshot) => {
                    let costumes = [];
                    querySnapshot.forEach((doc) => {
                        costumes.push({ id: doc.id, ...doc.data() });
                    });
                    commit(
                        "setCostumeOptions",
                        costumes.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    );
                },
                (error) => {
                    console.log(error);
                }
            );

            const addonsRef = collection(
                db,
                `${collections.EVENTS}/${eventId}/${subCollections.AVAILABILITY}/${availability.id}/${subCollections.ADD_ONS}`
            );
            onSnapshot(
                addonsRef,
                (querySnapshot) => {
                    let addons = [];
                    querySnapshot.forEach((doc) => {
                        addons.push({ id: doc.id, ...doc.data() });
                    });
                    commit(
                        "setAddonsOptions",
                        addons.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    );
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        async getTripCost({ commit, state, dispatch }) {
            const costumePrice = state.costumePrice;
            const travellers = state.totalTravellers;
            const discount = state.totalTripDiscount * travellers;
            const transportPrice = parseFloat(state.selectedTransport.price * travellers) || 0;
            const basePrice = parseFloat(state.basePrice * travellers);
            const hotelPrice = parseFloat(state.selectedHotel.rooms * state.selectedHotel.price) || 0;
            const totalTripCost = parseFloat(basePrice + hotelPrice + transportPrice + costumePrice - discount);
            commit("setTotalTripCost", totalTripCost);
            dispatch("getTripDeposit", { totalTripCost: totalTripCost });
        },
        async getStripeAmount({ state, commit, dispatch }, { paymentAmount }) {
            const stripeAmount = Math.trunc(((paymentAmount + stripeFees.TRXN_FEE) / stripeFees.MDR).toFixed(2) * 100);
            commit("setStripePaymentAmount", stripeAmount);
            if (state.stripeClientSecret) {
                dispatch("updateStripePaymentIntent", { stripeAmount: stripeAmount });
            }
        },
        async updateStripePaymentIntent({ state, commit }, { stripeAmount }) {
            if (state.stripeClientSecret) {
                commit("setIsReservationFormBusy", true);
                const [paymentIntent] = state.stripeClientSecret.split("_secret_");
                await fetch("https://app.socaislands.com/api/stripe/update", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        stripeAmount: stripeAmount,
                        paymentIntent: paymentIntent,
                    }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        commit("setIsReservationFormBusy", false);
                        return data;
                    });
            }
        },
        dateDiff({ state, commit }) {
            const dateDiff = parseInt((state.availability.start_date - moment().unix() * 1000) / 86400000);
            commit("setTimeToTrip", dateDiff);
            return dateDiff;
        },
        async getTripDeposit({ commit, state, dispatch }, { totalTripCost }) {
            let deposit = 0;
            const dateDiff = await dispatch("dateDiff");
            if (dateDiff > 90) {
                deposit = totalTripCost * 0.15;
            } else if (dateDiff > 60 && dateDiff <= 90) {
                deposit = totalTripCost * 0.5;
            } else if (dateDiff <= 60) {
                deposit = totalTripCost;
            }

            if (state.payFullAmount === "payFullAmount") {
                commit("setPaymentAmount", totalTripCost);
            } else if (state.payFullAmount === "payDeposit") {
                commit("setPaymentAmount", deposit);
            } else if (state.payFullAmount === "payMonthly") {
                commit("setPaymentAmount", deposit * 1.334);
            }

            commit("setTotalTripDeposit", deposit);
            dispatch("getStripeAmount", { paymentAmount: state.paymentAmount });
        },
        async saveTransaction({ state }, payload) {
            try {
                const { orderId, paymentIntent, userEmail } = payload;
                const { selectedPaymentMethod, paymentAmount, stripePaymentAmount } = state;
                const trxnDate = Date.now();
                const amount = Number(parseFloat(paymentAmount).toFixed(2));
                const fees =
                    paymentIntent === null
                        ? 0
                        : Number(parseFloat((stripePaymentAmount / 100 - paymentAmount).toFixed(2)));

                const transaction = {
                    trxn_ref: Date.now(),
                    trxn_date: trxnDate,
                    amount,
                    fees,
                    stripe_data: paymentIntent,
                    notes: "",
                    payment_method: selectedPaymentMethod,
                    user: userEmail ?? state.loggedInUser.uid,
                };

                const trxnRef = await addDoc(collection(db, collections.PAYMENTS), transaction);

                const journalEntry = {
                    order_id: orderId,
                    trxn_id: trxnRef.id,
                    trxn_date: trxnDate,
                    amount,
                    payment_method: selectedPaymentMethod,
                    user: userEmail ?? state.loggedInUser.uid,
                };

                await addDoc(
                    collection(db, `${collections.PAYMENTS}/${trxnRef.id}/${subCollections.JOURNAL_ENTRIES}`),
                    journalEntry
                );

                return true;
            } catch (error) {
                return error;
            }
        },
        getTravellerCostumeInfo({ state }, { index }) {
            const { selectedCostumes, selectedCostumesData } = state;

            let costume = null,
                costumeData = null,
                addons = null;

            if (selectedCostumes[index] !== "no") {
                costume = {
                    ...selectedCostumes[index],
                };

                delete costume.quantity;
                delete costume.sold;

                console.log("index", index);
                console.log("costume", costume);
                console.log("bra", selectedCostumesData);
                console.log("bra", selectedCostumesData.bra_type[index]);

                costumeData = {
                    bra_type: selectedCostumesData.bra_type[index] || null,
                    bra_size: selectedCostumesData.bra_size[index] || null,
                    underwear_type: selectedCostumesData.underwear_type[index] || null,
                    underwear_size: selectedCostumesData.underwear_size[index] || null,
                    pants_size: selectedCostumesData.pants_size[index] || null,
                };

                if (Array.isArray(selectedCostumesData.addons)) {
                    addons = selectedCostumesData.addons
                        .filter((addon) => addon.idx === index)
                        .reduce((a, v) => ({ ...a, [v.id]: v }), {});

                    if (Object.keys(addons).length < 1) {
                        addons = null;
                    }
                }
            }

            return { costume: costume, costumeData: costumeData, addons: addons };
        },
        async saveTravellerDetails({ state, dispatch }, payload) {
            const { orderId } = payload;
            const {
                travellers,
                pricePerTraveller,
                totalTravellers,
                selectedPaymentMethod,
                paymentAmount,
                selectedEvent,
            } = state;
            console.log("565", selectedEvent?.experience_category);
            for (let i = 0; i < totalTravellers; i++) {
                const costumeInfo =
                    selectedEvent?.experience_category === "carnival"
                        ? await dispatch("getTravellerCostumeInfo", { index: i })
                        : null;

                let travellerDetails = {
                    order_id: orderId,
                    order_total: pricePerTraveller[i],
                    order_discount: state.totalTripDiscount,
                    order_payments:
                        selectedPaymentMethod == paymentMethod.CREDIT_CARD ? paymentAmount / totalTravellers : 0,
                    fname: travellers.fname[i],
                    lname: travellers.lname[i],
                    email: travellers.email[i],
                    phone: travellers.phone[i],
                    gender: travellers.gender[i],
                    tshirt_size: travellers.tshirt_size[i],
                    notes: travellers.notes[i] || null,
                    costume: costumeInfo?.costume || null,
                    costumeData: costumeInfo?.costumeData || null,
                    addons: costumeInfo?.addons || null,
                };

                addDoc(
                    collection(db, `${collections.ORDERS}/${orderId}/${subCollections.TRAVELLERS}`),
                    travellerDetails
                );
            }
        },
        async saveReservation({ state, dispatch }, payload = { orderStatus: "on-hold" }) {
            const { orderStatus } = payload;
            const {
                selectedEvent,
                travellers,
                availability,
                totalTravellers,
                selectedCostumes,
                selectedAddons,
                selectedHotel,
                selectedTransport,
                selectedPaymentMethod,
                paymentAmount,
            } = state;
            const clientIp = await dispatch("getIpAddress");

            delete selectedEvent.sold;
            delete selectedEvent.quantity;
            delete availability.sold;
            delete availability.quantity;

            const reservation = {
                user: state.loggedInUser.uid,
                ref: Date.now(),
                trip_type: selectedEvent.experience_category,
                trip_event: selectedEvent,
                trip_date: availability,
                order_date: Date.now(),
                order_status: orderStatus,
                order_payments: selectedPaymentMethod == paymentMethod.CREDIT_CARD ? paymentAmount : 0,
                order_total: state.totalTripCost,
                order_deposit: state.totalTripDeposit,
                order_discount: state.totalTripDiscount * state.totalTravellers,
                accommodation: selectedHotel,
                transportation: selectedTransport,
                travellers: travellers.fname,
                pay_full_amount: state.payFullAmount,
                policies: {
                    terms_conditions: true,
                    privacy_policy: true,
                    covid_refund_policy: true,
                    third_party_terms: true,
                    travel_insurance: true,
                },
                client_ip: clientIp,
            };

            // Create a reference to the required docs.
            const orderId = uuidv4();
            const orderDocRef = doc(db, collections.ORDERS, orderId);
            const prefix = `${collections.EVENTS}/${selectedEvent.id}/${subCollections.AVAILABILITY}/${availability.id}`;
            const packageDocRef = doc(
                db,
                `${collections.EVENTS}/${selectedEvent.id}/${subCollections.AVAILABILITY}`,
                availability.id
            );
            const hotelsDocRef = doc(db, `${prefix}/${subCollections.HOTELS}`, selectedHotel.id);
            const transportDocRef = doc(db, `${prefix}/${subCollections.TRANSPORT}`, selectedTransport.id);

            try {
                await runTransaction(db, async (transaction) => {
                    const packageDoc = await transaction.get(packageDocRef);
                    const hotelsDoc = await transaction.get(hotelsDocRef);
                    const transportDoc = await transaction.get(transportDocRef);

                    if (!packageDoc.exists()) {
                        throw "Document does not exist!";
                    }

                    let canSaveOrder = true;

                    const packageQuantity = packageDoc.data().quantity;
                    const packageSold = parseInt(packageDoc.data().sold + totalTravellers);

                    const hotelExists = hotelsDoc.exists();
                    const hotelQuantity = hotelExists ? hotelsDoc.data().quantity : 0;
                    const hotelSold = hotelExists ? parseInt(hotelsDoc.data().sold + selectedHotel.rooms) : 0;

                    const transportSold = parseInt(transportDoc.data().sold + totalTravellers);

                    if (packageQuantity < packageSold || hotelQuantity < hotelSold) {
                        canSaveOrder = false;
                    }

                    if (canSaveOrder) {
                        transaction.update(packageDocRef, { sold: packageSold });
                        transaction.update(transportDocRef, { sold: transportSold });
                        transaction.set(orderDocRef, reservation);

                        //update hotel if selected
                        if (hotelExists) {
                            transaction.update(hotelsDocRef, { sold: hotelSold });
                        }

                        if (selectedEvent?.experience_category === "carnival") {
                            //update costumes
                            for (let i = 0; i < selectedCostumes.filter((costume) => costume != "no").length; i++) {
                                const costumeDocRef = doc(
                                    db,
                                    `${prefix}/${subCollections.COSTUMES}`,
                                    selectedCostumes[i]?.id
                                );
                                transaction.update(costumeDocRef, { sold: increment(1) });
                            }

                            //update addons
                            for (let i = 0; i < selectedAddons.length; i++) {
                                const addonDocRef = doc(
                                    db,
                                    `${prefix}/${subCollections.ADD_ONS}`,
                                    selectedAddons[i]?.id
                                );
                                transaction.update(addonDocRef, { sold: increment(1) });
                            }
                        }
                        return orderId;
                    } else {
                        return Promise.reject("Sorry! Not enough spots available.");
                    }
                });
                return { orderId: orderId };
            } catch (e) {
                console.error(e);
                return { error: e };
            }
        },
        async sendNewEmail({}, emailData) {
            addDoc(collection(db, collections.EMAIL_MESSAGES), emailData);
            return false;
        },
        resetReservation({ commit, dispatch }) {
            commit("setPaymentAmount", 0);
            commit("setPayFullAmount", null);
            commit("setSelectedTransport", {});
            commit("setSelectedHotel", {});
            commit("setSelectedCostumes", []);
            commit("setTotalTravellers", 0);
            commit("setCostumePrice", 0);
            commit("setStripeClientSecret", "");
            commit("setSelectedPaymentMethod", null);
            commit("setIsReservationFormBusy", false);
            dispatch("getTripCost");
        },
        async getIpAddress() {
            const clientIp = await fetch(CLIENT_IP_SERVICE)
                .then((response) => response.json())
                .then((data) => {
                    return data;
                });
            return clientIp;
        },
        saveMessagingDeviceToken({ dispatch }, user) {
            getToken(messaging, {
                vapidKey: "BNvIEh59YfC-I3C4saFsuCT_uDW2UaL2bmkslWyLMO5bhy8FUjSkJfrcXTWQLzBt5fKEtZPD9_uqtrJCYDJdoIY",
            })
                .then((currentToken) => {
                    if (currentToken) {
                        setDoc(doc(db, collections.FCM_TOKENS, user.uid), { fcmToken: currentToken });
                    } else {
                        dispatch("requestNotificationsPermissions", user);
                    }
                })
                .catch((error) => {
                    console.error("Unable to get messaging token.", error);
                });
        },
        requestNotificationsPermissions({ dispatch }, user) {
            messaging
                .requestPermission()
                .then(() => {
                    dispatch("saveMessagingDeviceToken", user);
                })
                .catch((error) => {
                    console.error("Unable to get permission to notify.", error);
                });
        },
        async fetchProfile({ commit, state }, user) {
            try {
                //Load profile
                const profileDocRef = doc(db, collections.PROFILES, user.uid);
                onSnapshot(profileDocRef, (doc) => {
                    commit("setUserProfile", { id: doc.id, ...doc.data() });
                    commit("setProfileDoc", doc.id);
                });

                const profileContctDocRef = doc(db, collections.CONTACTS, user.uid);
                onSnapshot(profileContctDocRef, (doc) => {
                    commit("setUserProfile", { ...state.userProfile, ...doc.data() });
                });

                //Load messages
                const q = query(
                    collection(db, collections.MESSAGES),
                    where("users", "array-contains", user.uid),
                    orderBy("updated_at", "desc")
                );
                onSnapshot(q, (querySnapshot) => {
                    const messages = [];
                    querySnapshot.forEach((doc) => {
                        messages.push({ id: doc.id, ...doc.data() });
                    });
                    if (messages.length < 1) {
                        commit("setMessages", []);
                    } else {
                        commit("setMessages", messages);
                    }

                    querySnapshot.docChanges().forEach((change) => {
                        if (change.type === "added") {
                            if (state.currentChatId === change.doc.id && !change.doc.data().read.includes(user.uid)) {
                                updateDoc(doc(db, collections.MESSAGES, change.doc.id), {
                                    read: arrayUnion(user.uid),
                                });
                            }
                        } else if (change.type === "modified") {
                            if (state.currentChatId === change.doc.id) {
                                updateDoc(doc(db, collections.MESSAGES, change.doc.id), {
                                    read: arrayUnion(user.uid),
                                });
                            }
                        } else if (change.type === "removed") {
                            //...
                        }
                    });
                });
                commit("setPageLoading", false);
            } catch (error) {
                console.log(error);
            }
        },
        async getMarketplace({ commit, dispatch, state }) {
            try {
                const marketplaceQuery = query(
                    collection(db, collections.MARKETPLACE),
                    where("status", "==", true),
                    where("in_review", "==", false),
                    orderBy("updated_at", "desc")
                );
                onSnapshot(marketplaceQuery, (querySnapshot) => {
                    const marketplace = [];
                    if (!Array.isArray(state.marketplace)) {
                        querySnapshot.forEach((doc) => {
                            marketplace.push({ id: doc.id, ...doc.data() });
                        });
                        if (marketplace.length < 1) {
                            commit("setMarketplace", []);
                        } else {
                            commit("setMarketplace", marketplace);
                        }
                    } else {
                        querySnapshot.docChanges().forEach((change) => {
                            if (change.type === "added") {
                                commit("setStagingMarketplace", { id: change.doc.id, ...change.doc.data() });
                                const changeAuthor = change.doc.data().author.id;
                                const userId = state.userProfile.id;
                                if (changeAuthor === userId) {
                                    dispatch("loadMarketplacePosts");
                                }
                            } else if (change.type === "modified") {
                                const index = state.marketplace.findIndex((item) => item.id === change.doc.id);
                                commit("updateMarketplaceItem", {
                                    index: index,
                                    data: { id: change.doc.id, ...change.doc.data() },
                                });
                            } else if (change.type === "removed") {
                                const index = state.marketplace.findIndex((item) => item.id === change.doc.id);
                                commit("removeMarketplaceItem", index);
                            }
                        });
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        async getCrewfinder({ commit, dispatch, state }) {
            try {
                const crewfinderQuery = query(
                    collection(db, collections.CREWFINDER),
                    where("status", "==", true),
                    where("in_review", "==", false),
                    orderBy("updated_at", "desc")
                );
                onSnapshot(crewfinderQuery, (querySnapshot) => {
                    const crewfinder = [];
                    if (!Array.isArray(state.crewfinder)) {
                        querySnapshot.forEach((doc) => {
                            crewfinder.push({ id: doc.id, ...doc.data() });
                        });
                        if (crewfinder.length < 1) {
                            commit("setCrewfinder", []);
                        } else {
                            commit("setCrewfinder", crewfinder);
                        }
                    } else {
                        querySnapshot.docChanges().forEach((change) => {
                            if (change.type === "added") {
                                commit("setStagingCrewfinder", { id: change.doc.id, ...change.doc.data() });
                                const changeAuthor = change.doc.data().author.id;
                                const userId = state.loggedInUser.uid;
                                if (changeAuthor === userId) {
                                    dispatch("loadCrewfinderPosts");
                                }
                            } else if (change.type === "modified") {
                                const index = state.crewfinder.findIndex((item) => item.id === change.doc.id);
                                commit("updateCrewfinderItem", {
                                    index: index,
                                    data: { id: change.doc.id, ...change.doc.data() },
                                });
                            } else if (change.type === "removed") {
                                const index = state.crewfinder.findIndex((item) => item.id === change.doc.id);
                                commit("removeCrewfinderItem", index);
                            }
                        });
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        loadMarketplacePosts({ commit, state }) {
            const tempMarketplace = [...state.stagingMarketplace, ...state.marketplace];
            commit("setMarketplace", tempMarketplace);
            commit("clearStagingMarketplace");
        },
        loadCrewfinderPosts({ commit, state }) {
            const tempCrewfinder = [...state.stagingCrewfinder, ...state.crewfinder];
            commit("setCrewfinder", tempCrewfinder);
            commit("clearStagingCrewfinder");
        },
        openExternalLink({}, { url }) {
            window.location.href = url;
        },
        init({ dispatch }) {
            dispatch("getMarketplace");
            dispatch("getCrewfinder");
            dispatch("getEvents");
        },
    },
    modules: {},
});
